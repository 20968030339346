@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";


/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.e-popup-details {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
} 

.price-icon {
  background-image: url('./media/icons/argent.png');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: left center;
  padding-left: 32px; 
}

.customer-icon {
  background-image: url('./media/icons/poignee-de-main.png');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: left center;
  padding-left: 32px; 
}

/* Ajuste la largeur de la colonne des salles dans la vue Agenda */
.e-schedule-table .e-content-table .e-resource-column {
  width: 200px !important;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.schedule-wrapper {
  flex-grow: 1;
}

.left-side {
  float: left;
}

.right-side {
  float: right;
}

.toolbar-text {
  display: flex;
  margin: 2px 10px 0px 10px;
}